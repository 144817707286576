import { request, noTimeOutReq } from '@/utils/request.js'

// 分页查询实验教学计划
export function getExpPlanList (pageIndex, pageSize, data) {
  return request({
    url: `/crs/exp/plan/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}

// 新增实验教学计划
export function addExpPlan (data) {
  return request({
    url: `/crs/exp/plan/add`,
    method: 'post',
    data
  })
}
// 更新实验教学计划
export function updateExpPlan (data,crsExpPlanId) {
  return request({
    url: `/crs/exp/plan/update/${crsExpPlanId}`,
    method: 'post',
    data
  })
}
// 删除实验教学计划
export function deleteExpPlan (crsExpPlanId) {
  return request({
    url: `/crs/exp/plan/delete/${crsExpPlanId}`,
    method: 'post'
  })
}


// 获取查询执行学校条件
export function getExecutiveSchoolCondition (data) {
  return request({
    url: `/crs/exp/plan/page/executiveSchool/condition`,
    method: 'post',
    data
  })
}

// 获取查询执行学校条件
export function getExecutiveSchool (pageIndex, pageSize, data) {
  return request({
    url: `/crs/exp/plan/page/executiveSchool/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}

// 完成情况
// 实验教学计划概览
export function getPlanOverview (crsExpPlanId) {
  return request({
    url: `/crs/exp/plan/get/overview/${crsExpPlanId}`,
    method: 'post',
  })
}

// 实验教学计划提交明细
export function getPlanDetail (crsExpPlanId, pageIndex, pageSize, data) {
  return request({
    url: `/crs/exp/plan/page/detail/${crsExpPlanId}/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}

// 审批开课计划
export function submitExpPlan (crsExpPlanId, data) {
  return request({
    url: `/crs/exp/plan/submit/${crsExpPlanId}`,
    method: 'post',
    data
  })
}


// 查看详情
// 查询开课计划课程达标状况列表
export function getCourseStandardPlan (crsOpenPlanId) {
  return request({
    url: `/crs/exp/plan/list/courseStandard/${crsOpenPlanId}`,
    method: 'post',
  })
}

// // 获取课程表
// export function getClassSchedule (timestamp) {
//   return request({
//     url: `/course/open/plan/class/session/get/schedule/${timestamp}`,
//     method: 'post',
//   })
// }